import Vue from "vue";
import Router from "vue-router";

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `./${view}.vue`);
}

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      if (to.name == "Signup form" && from.name == "Signup form") {
        return;
      }
      if (savedPosition && savedPosition.y) {
        return window.scrollTo({
          top: savedPosition.offsetTop,
          behavior: "smooth",
        });
      } else {
        if (window.donotscroll) {
          window.donotscroll = false;
          return;
        }
        return window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
  },
  routes: [
    {
      path: "/",
      name: "Homepage",
      component: loadView("Homepage"),
    },
    {
      path: "/predictions",
      name: "Predictions",
      component: loadView("Homepage"),
    },
    {
      path: "/socials",
      name: "Homepage",
      component: loadView("Homepage"),
    },
    {
      path: "/blog",
      name: "Blog",
      component: loadView("Blog"),
    },
    {
      path: "/why",
      name: "WhyItWorks",
      component: loadView("WhyItWorks"),
    },
    {
      path: "/refer",
      name: "Affiliate",
      component: loadView("Affiliate"),
    },
    {
      path: "/reset",
      name: "ResetPassword",
      component: loadView("ResetPassword"),
    },
    {
      path: "/disclaimer",
      name: "Disclaimer",
      component: loadView("Disclaimer"),
    },
    {
      path: "/signup",
      name: "Signup",
      component: loadView("Signup"),
    },
    {
      path: "/signup/:type",
      name: "Signup form",
      component: loadView("SignupForm"),
    },
    {
      path: "/blog/:id",
      name: "BlogPost",
      component: loadView("BlogPost"),
    },
  ],
});

export default router;
