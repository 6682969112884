import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    accessTokens: {},
    subscriptionType: false,
    customerID: false,
    userID: false,
    isAmericas: false,
    isBetPromoAllowed: false,
    stripeProductCode: false,
  },
  mutations: {
    isAmericas(state, payload) {
      state.isAmericas = payload;
    },
    isBetPromoAllowed(state, payload) {
      state.isBetPromoAllowed = payload;
    },
    storeAccessTokens(state, payload) {
      state.accessTokens = payload;
      if (payload.access_token) {
        localStorage.setItem("NGAccessTokens", JSON.stringify(payload));
        // console.log("We just updated access tokens to", payload);
      } else {
        localStorage.removeItem("NGAccessTokens");
      }
    },
    storeSubscriptionType(state, payload) {
      state.subscriptionType = payload;
    },
    storeCustomerID(state, payload) {
      state.customerID = payload;
    },
    storeUserID(state, payload) {
      state.userID = payload;
      if (payload) {
        localStorage.setItem("NGUserID", payload);
      } else {
        localStorage.removeItem("NGUserID");
      }
    },
    updateStripeCode(state, payload) {
      state.stripeProductCode = payload;
    },
  },
  getters: {
    isAmericas(state) {
      return state.isAmericas;
    },
    isBetPromoAllowed(state) {
      return state.isBetPromoAllowed;
    },
    accessTokens(state) {
      return state.accessTokens;
    },
    subscriptionType(state) {
      return state.subscriptionType;
    },
    customerID(state) {
      return state.customerID;
    },
    userID(state) {
      return state.userID;
    },
    stripeProductCode(state) {
      return state.stripeProductCode;
    },
  },
});

export default store;
