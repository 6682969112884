import axios from "axios";
import store from "./store";
import createAuthRefreshInterceptor from "axios-auth-refresh";
let current = JSON.parse(localStorage.getItem("NGAccessTokens"));

// const API_PREFIX = `https://${process.env.NODE_ENV == 'development' ? 'dev-' : ''}api.numbersgame.uk`;
const API_PREFIX = `https://api.numbersgame.uk`;

let data = {
  refresh_token: current ? current.refresh_token : false,
  userid: localStorage.getItem("NGUserID"),
};

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  axios
    .post(
      `${API_PREFIX}/user/refresh`,
      data,
      false
    )
    .then((tokenRefreshResponse) => {
      // console.log("UPDATED TOKEN RESPONSE", tokenRefreshResponse.data);
      if (tokenRefreshResponse.data) {
        store.commit("storeAccessTokens", tokenRefreshResponse.data);

        failedRequest.response.config.headers["Authorization"] =
          tokenRefreshResponse.data.id_token;
        window.location = window.origin;
      } else {
        localStorage.clear();
        window.location = window.origin;
      }
    })
    .catch((error) => {
      console.log("Got an error", error);
      localStorage.clear();
      window.location = window.origin;
    });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axios, refreshAuthLogic);

const API = {
  get(path, auth, params) {
    console.log(`GET request for data from: ${path}`);
    if (auth) {
      if (auth.ALWAYSIGNOREAUTH) {
        delete axios.defaults.headers.get["Authorization"];
      } else {
        axios.defaults.headers.get["Authorization"] = `${auth.idToken}`;
      }
      if (auth.REMOVEPREFIX){
        return axios.get(`${path}`, params);
      }
    }
    return axios.get(`${API_PREFIX}${path}`, params);
  },

  post(path, auth, params) {
    if (auth) {
      axios.defaults.headers.post["Authorization"] = `${auth.idToken}`;
    }
    // console.log(
    //   `POST request to: ${path} with data: ${JSON.stringify(params)}`
    // );
    return axios.post(`${API_PREFIX}${path}`, params);
  },
};

export default API;
