import Vue from "vue";
import App from "./App.vue";
import "./main.css";

import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import inViewportDirective from "vue-in-viewport-directive";
Vue.directive("in-viewport", inViewportDirective);

import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
